import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { QueryParamsHandling, Router } from '@angular/router';
import { ResponsiveService } from '@cf/core';

@Component({
  selector: 'cf-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrl: './sidenav.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class SidenavComponent implements OnInit {
  @HostBinding('class.pinned') pinned!: boolean;

  @Input() isAuthorized: boolean | null = false;

  @Output() toggleSidenav = new EventEmitter();

  isMobile!: boolean;

  isTouch$ = this.responsiveService.isTouch$;
  isMobile$ = this.responsiveService.isMobile$;

  private _mouseleave!: ReturnType<typeof setTimeout>;

  constructor(
    private readonly responsiveService: ResponsiveService,
    private readonly router: Router,
    private readonly elementRef: ElementRef
  ) {}

  @HostListener('mouseenter') onMouseEnter() {
    clearTimeout(this._mouseleave);
    setTimeout(() => {
      this.elementRef.nativeElement.classList.add('hovered');
    }, 150);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this._mouseleave = setTimeout(() => {
      this.elementRef.nativeElement.classList.remove('hovered');
    }, 150);
  }

  ngOnInit() {
    this.checkPinnedState();

    this.isTouch$.subscribe((isTouch) => {
      if (isTouch) {
        this.pinned = false;
      } else {
        this.checkPinnedState();
      }
    });

    this.isMobile$.subscribe((isMobile) => (this.isMobile = isMobile));
  }

  getQueryParamsHandling(route: string): QueryParamsHandling {
    if (this.router.url.includes(route)) {
      return 'preserve';
    }
    return '';
  }

  closeSidenav() {
    if (this.isMobile) {
      this.toggleSidenav.emit();
    } else {
      if (!this.pinned) {
        this.immediatelyClose();
      }
    }
  }

  immediatelyClose() {
    setTimeout(() => {
      this.unpinNavigation();
      this.elementRef.nativeElement.classList.remove('hovered');
    }, 100);
  }

  pinNavigation() {
    localStorage.setItem('pinned', 'true');
    this.pinned = true;
  }

  unpinNavigation() {
    localStorage.setItem('pinned', 'false');
    this.pinned = false;
  }

  private checkPinnedState() {
    if (!localStorage.getItem('pinned')) {
      this.pinNavigation();
    }
    if (localStorage.getItem('pinned') === 'true') {
      this.pinned = true;
    }
  }
}
