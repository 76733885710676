import { EnvironmentData } from '@cf/core';

export const environment: EnvironmentData = {
  production: true,
  enabledNamespaces: [],
  apiUrl: 'https://api.dev.calfrenzy.bitstudios.dev/',
  appUrl: 'https://dev.calfrenzy.bitstudios.dev',
  publicUrl: 'https://calfrenzy.bitstudios.dev/',
  domain: '.calfrenzy.bitstudios.dev',
  envName: 'dev',
  googleClientId:
    '369573383535-l7ummcbah51d38iccfk1585hvhmnb8np.apps.googleusercontent.com',
  googleMapId: 'AIzaSyAYez6hHF2CrUbDnJ_lA7P-bSj5fbQq89U',
  zoomClientId: 'BvATuJS_RJGIzYSNBxQKA',
  buildVersion: '7cb32fe39ec26a493e1c5ed00f80e28591ebf01d',
};
