import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

import {
  AccountService,
  IUserNotice,
  LanguageEnum,
  UsersService,
} from '@cf/temp/services';
import { UsersnapService } from '../../../services/usersnap.service';
import { LayoutService } from '../../services';

@Component({
  selector: '[cf-header-user-flow]',
  templateUrl: './header-user-flow.component.html',
  styleUrls: ['./header-user-flow.component.scss'],
  providers: [UsersnapService],
})
export class HeaderUserFlowComponent implements OnInit, OnChanges {
  @Input() isAuthorized: boolean | null = false;

  @Output() register: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() login: EventEmitter<unknown> = new EventEmitter<unknown>();

  @Output() logout: EventEmitter<unknown> = new EventEmitter<unknown>();

  notices$!: Observable<IUserNotice[]>;

  noticeSub$!: Subscription;

  backUrl!: string | null | boolean;

  constructor(
    public readonly accountService: AccountService,
    public readonly translateService: TranslateService,
    public readonly layoutService: LayoutService,
    private readonly usersService: UsersService,
    private readonly usersnapService: UsersnapService,
    private readonly cd: ChangeDetectorRef
  ) {
    this.accountService.account$.subscribe((account) => {
      this.translateService.use(account.language ?? LanguageEnum.en);
    });
  }

  ngOnInit() {
    this.layoutService.backUrl$.subscribe((url) => {
      this.backUrl = url;
      this.cd.detectChanges();
    });
  }

  ngOnChanges() {
    this.loadUsersnap();
    if (this.isAuthorized) {
      this.notices$ = this.usersService.notices();
    }
  }

  dismissNotice(id: number) {
    this.noticeSub$?.unsubscribe();
    this.noticeSub$ = this.usersService.noticeDismiss(id).subscribe(() => {
      this.notices$ = this.usersService.notices();
    });
  }

  private loadUsersnap() {
    const account = this.accountService.account;
    if (account.email) {
      void this.usersnapService.initialize({
        user: { userId: account.id.toString(), email: account.email },
      });
    } else {
      void this.usersnapService.initialize();
    }
  }
}
