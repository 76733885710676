<ng-container
  *ngIf="isAuthorized; then authorized; else notAuthorized"
></ng-container>

<ng-template #authorized>
  @for (notice of notices$ | async; track $index) {
    <cf-user-notice
      class="mb-3"
      [notice]="notice"
      (emitDismiss)="dismissNotice(notice.id)"
    ></cf-user-notice>
  }

  <div class="wrapper" *ngIf="accountService.account$ | async as account">
    <cf-back-button *ngIf="backUrl" [link]="backUrl"></cf-back-button>

    <div class="ms-auto">
      <cf-dropdown placement="bottom-right">
        <cf-avatar
          cf-dropdown-toggle
          alt="User Logo"
          [src]="account.profile_picture"
        ></cf-avatar>
        <cf-dropdown-item routerLink="/settings/profile">
          {{ 'header.my_profile' | translate }}
        </cf-dropdown-item>
        @if (account.is_superuser) {
          <cf-dropdown-item routerLink="/stats"> Admin Stats </cf-dropdown-item>
        }

        <cf-dropdown-item (click)="logout.emit()">
          {{ 'header.logout' | translate }}
        </cf-dropdown-item>
      </cf-dropdown>
    </div>
  </div>
</ng-template>

<ng-template #notAuthorized>
  <div class="ms-auto">
    <cf-google-one-tap></cf-google-one-tap>
  </div>
</ng-template>
